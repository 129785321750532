
import { Component, Mixins } from "vue-property-decorator";
import { initializeApp } from "firebase/app";
import FormValidator from "@/mixins/FormValidator";
import {
  getAuth,
  applyActionCode,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from "firebase/auth";

interface formData {
  password: string;
  confirmPassword: string;
}

@Component
export default class RecoverPassword extends Mixins(FormValidator) {

  protected formData: formData = {
    password: "",
    confirmPassword: ""
  }

  // Variables de estado
  private baseImagen = process.env.VITE_VUE_APP_AWS_ASSETS;
  private title = "Cargando...";
  private status = "loading";
  private errorMessage = "";
  //const router = useRouter();
  private showPassword : Boolean = false;
  private showConfirmPassword : Boolean = false;
  //private form = FormValidator();
  //private const compareValuesPass = form;

  // parámetros de la query
  private routeQuery = new URLSearchParams(window.location.search);
  private actionCode: string = this.routeQuery.get("oobCode") ?? "";
  private mode: string = this.routeQuery.get("mode") ?? "";
  private apiKey: string = this.routeQuery.get("apiKey") ?? "";

  // Configuración Firebase
  private firebaseConfig = { apiKey: this.apiKey };
  private app = initializeApp(this.firebaseConfig);
  private auth = getAuth(this.app);

  /*const validateConfirmPassword = (value: any) => {
    return compareValuesPass(password.value, value);
  };*/

  private confirmPasswordRef = null;

  private url_frontoffice = process.env.VUE_APP_FRONT_OFFICE_URL as string;

  /*@Watch('password')
  validarContrasena(() => {
    if (confirmPasswordRef.value) {
      confirmPasswordRef.value.validate();
    }
  });*/

  async mounted(){
    if (!this.actionCode || !this.mode) {
      window.open(this.url_frontoffice, "_self");
      return;
    }

    // muestra según el `mode`
    try {
      if (this.mode === "resetPassword" && this.actionCode) {
        this.title = "Reestablecer Contraseña";
        await verifyPasswordResetCode(this.auth, this.actionCode); // Verifica el código
        this.status = "passwordReset";
      } else if (this.mode === "verifyEmail" && this.actionCode) {
        this.title = "Verificación de Cuenta";
        await applyActionCode(this.auth, this.actionCode); // Aplica el código para verificar el correo
        this.status = "verified";
      } else {
        throw new Error("Modo no soportado o datos faltantes.");
      }
    } catch (error: any) {
      if (error.code === "auth/invalid-api-key") {
        this.errorMessage =
          "Error en la configuración del sistema. Contacte al Municipio.";
      } else if (error.code === "auth/invalid-action-code") {
        this.errorMessage =
          "El enlace ya no es válido. Por favor, solicita un nuevo enlace para continuar.";
      } else if (error.code === "auth/expired-action-code") {
        this.errorMessage =
          "El enlace ha caducado. Por favor, solicita un nuevo enlace para continuar.";
      } else {
        this.errorMessage =
          "Ocurrió un error: " + (error.message || "Acción inválida.");
      }
      this.status = "error";
    }
  }

  validatePassword() {
    if (this.formData.password.length == 0 || this.formData.confirmPassword.length == 0) {
      this.notify("Las contraseñas no puedes estar vacías")
      return false;
    }
    if (this.formData.password.length <= 5) {
      this.notify("La contraseña debe tener al menos 6 caracteres")
      return false;
    }
    if (this.formData.password !== this.formData.confirmPassword) {
      this.notify("Las contraseñas no coinciden")
      return false;
    }
    return true;
  };

  private async handlePasswordReset(){
    if (!this.validatePassword()) return;

    try {
      await confirmPasswordReset(this.auth, this.actionCode, this.formData.password);
      this.status = "verified";
      this.title = "Reestablecer Contraseña";
    } catch (error) {
      this.status = "error";
      this.errorMessage =
        "No se pudo cambiar la contraseña. Inténtelo más tarde.";
    }
  };

  private notify(message: string) {
    this.$q.notify({
      message: message,
      color: "principal",
      position: "top",
      timeout: 5000
    });
  }

  protected togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  };

  protected handleRedirectOrOpenApp() {
    window.open(this.url_frontoffice, "_self");
  };

  protected toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  };
}
